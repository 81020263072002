<template>
  <section class="section section-ayun section-md my-0">
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div
            v-if="$store.getters['user/loginErrorMessage']"
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-inner--text">
              <strong>Oops!</strong>
              {{ $store.getters["user/loginErrorMessage"] }}
            </span>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
          >
            <template>
              <div class="text-muted text-center mb-3">
                <h3>Login</h3>
              </div>
            </template>

            <template>
              <form role="form">
                <base-input
                  alternative
                  class="mb-3"
                  placeholder="Email"
                  v-model="form.email"
                  addon-left-icon="ni ni-email-83"
                />

                <base-input
                  alternative
                  type="password"
                  placeholder="Password"
                  v-model="form.password"
                  addon-left-icon="ni ni-lock-circle-open"
                />

                <div class="text-center">
                  <base-button
                    v-if="$store.getters['user/isAuthenticating']"
                    type="primary"
                    class="my-4 btn-block"
                  >
                    <i class="fa fa-refresh fa-spin"></i>
                  </base-button>
                  <base-button
                    v-else
                    @click="login"
                    type="primary"
                    class="my-4 btn-block"
                  >
                    Sign In
                  </base-button>
                </div>

                <div class="row mt-3">
                  <div class="col-6">
                    <base-checkbox>Remember me</base-checkbox>
                  </div>
                  <div class="col-6">
                    <router-link to="/forgot-password">
                      <a href="#" class="text-light"> Forgot password? </a>
                    </router-link>
                  </div>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      this.$store.dispatch("user/loginAction", this.form);
    },
  },
};
</script>

<style scoped>
.btn {
  background-color: #122b91 !important;
  border: #122b91 !important;
}

a,
.text-light {
  color: #fff;
  font-size: 0.875rem;
}

.section-ayun {
  background-color: #122b91;
}
</style>
