<template>
  <section class="section my-5">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-12">
          <h3 class="display-3">
            {{ blog.title }}
          </h3>
        </div>
      </div>

      <div class="card">
        <img :src="blog.image.url" class="img-fluid" alt="blog image" />
        <div class="card-body">
          <h5 class="card-title">
            {{ blog.title }}
            <small class="text-muted pull-right">{{
              blog.dateCreated | dateFormat
            }}</small>
          </h5>
          <p class="card-text mb-3">{{ blog.description }}</p>
          <button
            class="btn btn-default text-white"
            @click="$router.push('/#blogs')"
          >
            <i class="fa fa-arrow-left"></i>&nbsp; Go Back
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "Blog",
  computed: mapState("blogs", ["blog"]),
  created() {
    this.initBlog();
  },
  methods: {
    initBlog() {
      const id = this.$route.params.id;
      this.$store.dispatch("blogs/fetchByIdAction", id);
    },
  },
};
</script>

<style scoped>
h3.display-3 {
  color: #122b91 !important;
}
#btn-edit {
  color: white;
}
.card-body {
  background-color: #fcfcfd;
}
</style>